import React, { useState, useEffect } from 'react';
import './Counter.css';

const Counter = () => {
  const [count105, setCount105] = useState(0);
  const [count203, setCount203] = useState(0);
  const [count120, setCount120] = useState(0);


  useEffect(() => {
    // Counter for 105+
    const interval105 = setInterval(() => {
      setCount105((prev) => {
        if (prev >= 105) {
          clearInterval(interval105);
          return 105; // Ensure it stops exactly at 105
        }
        return prev + 1;
      });
    }, 20); // Adjust speed as needed

    // Counter for 203+
    const interval203 = setInterval(() => {
      setCount203((prev) => {
        if (prev >= 203) {
          clearInterval(interval203);
          return 203; // Ensure it stops exactly at 203
        }
        return prev + 1;
      });
    }, 10); // Adjust speed as needed

    const interval120 = setInterval(() => {
        setCount120((prev) => {
          if (prev >= 120) {
            clearInterval(interval120);
            return 120; // Ensure it stops exactly at 203
          }
          return prev + 1;
        });
      }, 18); // Adjust speed as needed

    return () => {
      clearInterval(interval105);
      clearInterval(interval203);
      clearInterval(interval120);
    };
  }, []);

  return (
    <div className="counter-container dx">
      <div className="counter-item fig" data-aos="fade-left">
        <h1>{count105}+</h1>
        <p>Menu Selections</p>
      </div>
      <div className="counter-item" data-aos="fade-right">
        {/* <h1>{count203}+</h1>
        <p>Satisfied Clients</p> */}

        <img src="/images/ab.png" alt="" />
      </div>
      <div className="counter-item fig" data-aos="fade-left">
        <h1>{count120}+</h1>
        <p>Rooms Available</p>
      </div>
      <div className="counter-item" data-aos="fade-right">
        {/* <h1>{count203}+</h1>
        <p>Satisfied Clients</p> */}

        <img src="/images/aa.png" alt="" />
      </div>
    </div>
  );
};

export default Counter;
