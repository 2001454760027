import React, { useState } from "react";
import "./Carousel2.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const Carousel3 = () => {
  const slides = [
    {
      id: 1,
      image: "/images/ab.png",
      alt: "Innovative Solutions",
      title: "Innovative Solutions",
      description: "Discover cutting-edge strategies to transform your business with Zeavix.",
    },
    {
      id: 2,
      image: "/images/bed1.png",
      alt: "Collaborative Workspaces",
      title: "Collaborative Workspaces",
      description: "Empowering teamwork with modern tools and resources for unparalleled efficiency.",
    },
    {
      id: 3,
      image: "/images/bg5.png",
      alt: "Scalable Technology",
      title: "Scalable Technology",
      description: "Zeavix provides solutions that grow with your business needs, ensuring long-term success.",
    },
    {
      id: 4,
      image: "/images/bg6.png",
      alt: "Customer-Centric Approach",
      title: "Customer-Centric Approach",
      description: "At Zeavix, we prioritize our clients with tailored solutions for maximum satisfaction.",
    },
    {
      id: 5,
      image: "/images/bg5.png",
      alt: "Future-Driven Vision",
      title: "Future-Driven Vision",
      description: "Join Zeavix in embracing innovation and shaping a brighter tomorrow.",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [dragOffset, setDragOffset] = useState(0);

  const handlePrev = () => {
    if (currentIndex > 0) setCurrentIndex((prev) => prev - 1);
  };

  const handleNext = () => {
    if (currentIndex < slides.length - 1) setCurrentIndex((prev) => prev + 1);
  };

  const handleTouchStart = (e) => {
    setDragging(true);
    setDragStartX(e.touches[0].clientX);
    setDragOffset(0); // Reset offset when a new drag starts
  };

  const handleTouchMove = (e) => {
    if (dragging) {
      const currentTouchX = e.touches[0].clientX;
      const offset = currentTouchX - dragStartX;
      setDragOffset(offset);
    }
  };

  const handleTouchEnd = () => {
    setDragging(false);

    // Determine the threshold for slide movement
    if (dragOffset > 50 && currentIndex > 0) {
      handlePrev(); // Swipe right
    } else if (dragOffset < -50 && currentIndex < slides.length - 1) {
      handleNext(); // Swipe left
    }

    setDragOffset(0); // Reset the offset
  };

  return (
    <div
      className="carousel-container"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className="carousel">
        <div
          className="carousel-inner"
          style={{
            transform: `translateX(calc(-${currentIndex * 100}% + ${dragging ? dragOffset : 0}px))`,
            transition: dragging ? "none" : "transform 0.3s ease-in-out",
          }}
        >
          {slides.map((slide) => (
            <div key={slide.id} className="carousel-item">
              <img src={slide.image} className="d-block w-100" alt={slide.alt} />
              <div className="carousel-overlay">
                <h2 className="carousel-title">{slide.title}</h2>
                <p className="carousel-description">{slide.description}</p>
              </div>
            </div>
          ))}
        </div>
        <button
          className={`carousel-control-prev ${
            currentIndex === 0 ? "disabled" : ""
          }`}
          onClick={handlePrev}
          disabled={currentIndex === 0}
        >
          <IoIosArrowBack />
        </button>
        <button
          className={`carousel-control-next ${
            currentIndex === slides.length - 1 ? "disabled" : ""
          }`}
          onClick={handleNext}
          disabled={currentIndex === slides.length - 1}
        >
          <IoIosArrowForward />
        </button>
      </div>
    </div>
  );
};

export default Carousel3;
