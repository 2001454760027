import React from 'react';
import './Services.css';
import { Link } from 'react-router-dom';
import { FaCalendarAlt, FaBed, FaBuilding, FaUtensils } from 'react-icons/fa'; // Importing icons
import Instagram from './Instagram';

const servicesData = [
  {
    title: 'Event Planning',
    description: 'Professional event planning for all occasions, from weddings to corporate events.',
    icon: <FaCalendarAlt /> // Using react-icons
  },
  {
    title: 'Short Stays',
    description: 'Luxury short-stay accommodations for business or leisure.',
    icon: <FaBed /> // Using react-icons
  },
  {
    title: 'Venue Rentals',
    description: 'Book premium venues for your events, meetings, and more.',
    icon: <FaBuilding /> // Using react-icons
  },
  {
    title: 'Catering Services',
    description: 'Top-notch catering services for all types of events.',
    icon: <FaUtensils /> // Using react-icons
  }
];

const Services = () => {
  return (
    <section className="services" id="services">
      <div className="about-hero">
        <div className="hero-overlay">
          <h1 className="hero-heading">Services</h1>
          <p className="hero-subheading">
            Stay updated with the latest happenings at our hotels! From exciting events and special offers to exclusive insights and behind-the-scenes stories.
          </p>
          <br /><br />
          <div className="back-link">
            <Link to="/">
              <span>Home</span>
            </Link>
            <span> / Services</span>
          </div>
        </div>
      </div>
      <h2>Our Services</h2>
      <div className="services-container">
        {servicesData.map((service, index) => (
          <div className="service-card" key={index}>
            <div className="service-icon">{service.icon}</div>
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
      <Instagram/>
    </section>
  );
};

export default Services;
