import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import './Contact.css';
import { MdEmail } from 'react-icons/md';
import { Link } from 'react-router-dom';

const Contact = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [statusMessage, setStatusMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setStatusMessage('');

    // Check if environment variables are loaded correctly
    console.log("Service ID:", process.env.REACT_APP_EMAILJS_SERVICE_ID);
    console.log("Template ID:", process.env.REACT_APP_EMAILJS_TEMPLATE_ID);
    console.log("User ID:", process.env.REACT_APP_EMAILJS_USER_ID);

    // Validate form data
    if (!formData.name || !formData.email || !formData.message) {
      setStatusMessage('All fields are required.');
      setIsLoading(false);
      return;
    }

    // EmailJS send email logic with environment variables
    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      formData,
      process.env.REACT_APP_EMAILJS_USER_ID
    )
    .then((response) => {
      setStatusMessage('Message sent successfully!');
      setFormData({ name: '', email: '', message: '' });
    })
    .catch((error) => {
      console.error('EmailJS Error:', error);
      setStatusMessage('Failed to send message. Please try again later.');
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <section className="contact" id="contact">
      <div className="about-hero">
        <div className="hero-overlay">
          <h1 className="hero-heading">Contact Us</h1>
          <p className="hero-subheading">
          Stay updated with the latest happenings at our hotels! From exciting events and special offers to exclusive insights and behind-the-scenes stories.
          </p>
          <br /><br />
          <div className="back-link">
             <Link to="/">
              <span>Home</span>
            </Link>
            <span> / Contact Us</span>
         </div>
        </div>
      </div>
      <div className="contact-container">
        {/* Contact Form Section */}
        <div className="form-section">
          <form onSubmit={handleSubmit} className="contact-form">
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                rows="5"
                value={formData.message}
                onChange={handleInputChange}
                required
              ></textarea>
            </div>
            <button type="submit" className="submit-btn" disabled={isLoading}>
              {isLoading ? 'Sending...' : 'Send Message'}
            </button>
          </form>
          {statusMessage && <p className="status-message">{statusMessage}</p>}
        </div>

        {/* Additional Contact Details */}
        <div className="contact-details">
          <h3>Our Contact Details</h3>
          <p>
            <strong>Address:</strong>37 admiralty way, Lekki, Lagos, Nigeria
          </p>
          <p>
            <strong>Phone:</strong> +234 814 247 1208
          </p>
          <p>
            <strong>Email:</strong> zeavixxeventandmore@gmail.com
          </p>
          <p>
            <strong>Operating Hours:</strong> Monday - Sunday, 9:00 AM - 8:00 PM
          </p>

          {/* Social Media Links with Icons */}
          <div className="social-media">
            <h3>Connect With Us</h3>
            <div className="social-links">
              <a href="https://www.facebook.com/profile.php?id=61569412164029&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
                <FaFacebook size={30} />
              </a>
              <a href="https://www.twitter.com/zeavix" target="_blank" rel="noopener noreferrer">
                <FaTwitter size={30} />
              </a>
              <a href="https://www.instagram.com/zeavixeventandmore/profilecard/?igsh=YmxydG1wZmg0bXdu" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={30} />
              </a>
              <a href="https://www.linkedin.com/zeavix" target="_blank" rel="noopener noreferrer">
                <FaLinkedin size={30} />
              </a>
              <a href="mailto:zeavixxeventandmore@gmail.com"target="_blank" rel="noopener noreferrer">
                <MdEmail size={30} />
              </a>
            </div>
          </div>
        </div>

        {/* Google Map Section */}
        <div className="map-container">
          <iframe
            title="location-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1014805.2827248948!2d3.115414992642981!3d6.511160974705629!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bf75df5c74367%3A0x6a7e7df9d6c1cd4a!2sLekki%2C%20Lagos!5e0!3m2!1sen!2sng!4v1732343237615!5m2!1sen!2sng"
            width="100%"
            height="400"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default Contact;