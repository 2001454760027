// src/components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { useAuth } from '../auth/AuthContext';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { currentUser, logout } = useAuth();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="navbar">
      <a href="/https://www.instagram.com/zeavixeventandmore/?igsh=YmxydG1wZmg0bXdu" className="navbar-brand">
        <img src="/images/logo.png" alt="" />
        <h3>ZEAVIX</h3>
      </a>

      <div>
        <Link to={'/display'} className="navbar-book">
          BOOK NOW
        </Link>
        
      </div>

      <div className="auth">
        {/* {currentUser && <button onClick={logout}>Logout</button>}
        {!currentUser && <Link to="/login" className='auth-btn'>Login</Link>} */}
      </div>

      <div className="navbar-toggle" onClick={toggleMenu}>
        {isOpen ? '✖' : '☰'} {/* Toggles between ☰ and ✖ */}
      </div>

      <ul className={`navbar-menu ${isOpen ? 'open' : ''}`}>
        <li><Link to="/" onClick={closeMenu}>Home</Link></li>
        <li><Link to="/display" onClick={closeMenu}>Availables</Link></li>
        <li><Link to="/about" onClick={closeMenu}>About</Link></li>
        <li><Link to="/contact" onClick={closeMenu}>Contact</Link></li>
        <li><Link to="/services" onClick={closeMenu}>Services</Link></li>
        <li><Link to="/admin" onClick={closeMenu}>Admin</Link></li>
        <li><Link to="/facilities" onClick={closeMenu}>Facilities</Link></li>
      </ul>
    </nav>
  );
};

export default Navbar;
