import React from 'react';
import './About.css';
import { Link } from 'react-router-dom';
import Counter from './Counter';
import RoomAmenities from './RoomAmenities';
import Instagram from './Instagram';

const About = () => {
  return (
    <section className="about-us" id="about">
      <div className="about-hero">
        <div className="hero-overlay">
          <h1 className="hero-heading">About Us</h1>
          <p className="hero-subheading">
          ZEAVIX is your trusted partner for short stays, events, and more.
          </p>
          <br /><br />
          <div className="back-link">
             <Link to="/">
              <span>Home</span>
            </Link>
            <span> / About Us</span>
         </div>
        </div>
      </div>
      <div className="about-us-container">
        {/* Text Section */}
        <div className="about-us-text"    data-aos="fade-right">
          <br /><br />
          <h3>WELCOME</h3>
          <p>
            ZEAVIX is your trusted partner for short stays, events, and more. We specialize in providing top-tier event planning, venue rentals, and accommodations for both business and leisure. Our dedicated team ensures every experience is memorable, seamless, and tailored to your needs.
          </p>
          <p>
            From exquisite venues to exceptional catering, ZEAVIX is committed to making every occasion special. Our luxury short-stay accommodations offer the perfect blend of comfort and style, making us the top choice for discerning clients.
          </p>
          <ul>
            <li data-aos="fade-up" data-aos-delay="200">
              <strong>Event Excellence:</strong> Comprehensive event management from start to finish.
            </li>
            <li data-aos="fade-right" data-aos-delay="400">
              <strong>Luxury Accommodations:</strong> Experience unparalleled comfort and elegance.
            </li>
            <li data-aos="fade-left" data-aos-delay="600">
              <strong>Custom Solutions:</strong> Personalized services tailored to meet your unique needs.
            </li>
            <li data-aos="fade-right" data-aos-delay="800">
              <strong>Global Standards:</strong> Delivering world-class experiences for local and international clients.
            </li>
          </ul>
          <p data-aos="fade-up" data-aos-delay="1000">
            At ZEAVIX, we believe in redefining excellence, crafting unforgettable moments, and exceeding expectations. Join us as we turn your visions into reality.
          </p>
        </div>

        {/* Image Section */}
        <div className="about-us-image" data-aos="fade-left">
          <img src="/images/bg5.png" alt="About Us" />
        </div>
      </div>
      <Counter/>
      <RoomAmenities />
      <Instagram/>
    </section>
  );
};

export default About;
