import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase";

const AddToInstagram = () => {
  const [image, setImage] = useState("");
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState(false);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Add the post to Firebase Firestore
      const docRef = await addDoc(collection(db, "instagram"), {
        image,
        link,
      });
      console.log("Document written with ID: ", docRef.id);
      setImage("");
      setLink("");
    } catch (e) {
      console.error("Error adding document: ", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div  className="add-service">
      <h3>Add Instagram Post</h3>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Image URL"
          value={image}
          onChange={(e) => setImage(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Instagram Post Link"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          required
        />
        <button type="submit" disabled={loading}>
          {loading ? "Submitting..." : "Add Post"}
        </button>
      </form>
    </div>
  );
};

export default AddToInstagram;
