import React from "react";
import { MdLiveTv, MdShower, MdWork, MdBalcony, MdLocationCity } from "react-icons/md";
import { FaWifi, FaLock, FaBath } from "react-icons/fa";
import "./RoomAmenities.css";
import { RiFridgeLine } from "react-icons/ri";

const RoomAmenities = () => {
  const amenities = [
    { name: "Cable TV", icon: <MdLiveTv /> },
    { name: "Shower", icon: <MdShower /> },
    { name: "Safebox", icon: <FaLock /> },
    { name: "Free WiFi", icon: <FaWifi /> },
    { name: "Work Desk", icon: <MdWork /> },
    { name: "Refrigerator", icon: <RiFridgeLine /> },
    { name: "Balcony", icon: <MdBalcony /> },
    { name: "Bathtub", icon: <FaBath /> },
    { name: "City View", icon: <MdLocationCity /> },
  ];

  const features = [
    { label: "Wi-Fi", detail: "Complimentary High-Speed Wi-Fi" },
    { label: "Climate Control", detail: "Individual Air Conditioning and Heating" },
    { label: "Entertainment", detail: "50-inch Flat-Screen TV with Cable and Satellite" },
    { label: "Workspace", detail: "Ergonomic Work Desk and Chair" },
    { label: "Safety", detail: "In-Room Safe" },
    { label: "Communication", detail: "Direct-Dial Telephone with Voicemail" },
    { label: "Convenience", detail: "Alarm Clock, Iron, and Ironing Board" },
  ];

  return (
    <div className="room-details">
      <div className="amenities-section" data-aos="fade-up">
        <h2>Room Amenities</h2>
        <ul>
          {amenities.map((amenity, index) => (
            <li key={index}>
            <span className="icon">{amenity.icon}</span>
            {amenity.name}
          </li>

          ))}
        </ul>
      </div>
      <div className="features-section">
        <h2>Room Features</h2>
        <ul>
          {features.map((feature, index) => (
            <li key={index}>
              <strong>{feature.label}:</strong> {feature.detail}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default RoomAmenities;
