import React from 'react';
import './ExploreNow.css'; // Import the CSS file for styles
import { Link } from 'react-router-dom';

const ExploreNow = () => {
  return (
    <div className="explore-now-container">
      <div className="about-hero">
        <div className="hero-overlay">
          <h1 className="hero-heading">Discover a World of Opportunities</h1>
          <br /><br />
          <div className="back-link">
            <Link to="/">
              <span>Home</span>
            </Link>
            <span> / Services</span>
          </div>
        </div>
      </div>
      <header className="explore-header">
        <p className="explore-subtitle">
          Dive into new experiences, explore amazing events, and unlock exciting opportunities. Whether you're looking to host an event, find the perfect short stay, or explore additional services, we've got you covered.
        </p>
      </header>

      <section className="explore-cards-section">
        {/* Event Planning Card */}
        <div className="explore-card">
          <div className="card-content">
            <h2>Event Planning</h2>
            <p>
              From intimate gatherings to grand celebrations, our event planning services are designed to make your event unforgettable. Access top venues, professional decorators, and premium catering services to bring your vision to life.
            </p>
            <Link to={"/display"}>
            <button className="card-button">Learn More</button>
           </Link>
          </div>
        </div>

        {/* Short Stays Card */}
        <div className="explore-card">
          <div className="card-content">
            <h2>Short Stays</h2>
            <p>
              Find the perfect short stay accommodation tailored to your needs. Enjoy luxurious and comfortable stays in premium locations, with all the amenities you require for a pleasant experience.
            </p>
            <Link to={"/display"}>
            <button className="card-button">Learn More</button>
           </Link>
          </div>
        </div>

        {/* More Services Card */}
        <div className="explore-card">
          <div className="card-content">
            <h2>More Services</h2>
            <p>
              Discover additional services we offer to enhance your experience. From personalized travel packages, exclusive membership benefits, to bespoke experiences, there's something for everyone.
            </p>
           <Link to={"/display"}>
            <button className="card-button">Learn More</button>
           </Link>
          </div>
        </div>
      </section>

      {/* New section for testimonials */}
      <section className="testimonials-section">
        <h2>What Our Customers Say</h2>
        <div className="testimonial-card">
          <p>"ZEAVIX made my event a dream come true. The level of detail and attention they provided was exceptional. Highly recommended!"</p>
          <span>- Jane Doe, Event Host</span>
        </div>
        <div className="testimonial-card">
          <p>"Finding a short stay has never been easier. ZEAVIX offers the best places with top-notch service!"</p>
          <span>- John Smith, Traveler</span>
        </div>
      </section>

      {/* New section for Call to Action */}
      <section className="explore-cta-section">
        <h2>Ready to Explore?</h2>
        <p>Sign up today and get access to exclusive offers, unique locations, and unforgettable experiences.</p>
        <Link to={"https://wa.me/2348142471208"}>
         <button className="cta-button">Get Started</button>
        </Link>
      </section>
    </div>
  );
};

export default ExploreNow;
