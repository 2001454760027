import React, { useEffect, useRef } from "react";
import "./Facilities.css";
import { Link } from "react-router-dom";

const Facilities = () => {
  const sectionsRef = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;

      sectionsRef.current.forEach((section) => {
        if (section) {
          const rect = section.getBoundingClientRect();
          const image = section.querySelector(".facility-image img");

          if (rect.top < windowHeight && rect.bottom > 0) {
            const offset = (rect.top - windowHeight) / 6; // Increase divisor to slow down the image
            image.style.transform = `translateY(${offset}px)`; // Apply slower scrolling effect
          }
          
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const facilitiesData = [
    {
      title: "Cafe and Restaurant",
      description:
        "Our Cafe and Restaurant offers a variety of exquisite cuisines tailored to tantalize your taste buds. Enjoy a cozy ambiance with top-notch customer service. Our Cafe and Restaurant offers a variety of exquisite cuisines tailored to tantalize your taste buds. Enjoy a cozy ambiance with top-notch customer service.",
      image: "/images/bg.png",
    },
    {
      title: "Swimming Pool",
      description:
        "Relax and rejuvenate in our serene swimming pool. Perfect for a refreshing dip or a leisurely swim after a busy day. Relax and rejuvenate in our serene swimming pool. Perfect for a refreshing dip or a leisurely swim after a busy day.",
      image: "/images/bg.png",
    },
    {
      title: "Fitness Center",
      description:
        "Stay fit and active in our state-of-the-art fitness center, equipped with modern machines and personalized training programs. Stay fit and active in our state-of-the-art fitness center, equipped with modern machines and personalized training programs.",
      image: "/images/bg2.png",
    },
    {
      title: "Meeting Room",
      description:
        "Host your meetings and conferences in our fully equipped meeting rooms with high-speed internet and advanced presentation tools. Host your meetings and conferences in our fully equipped meeting rooms with high-speed internet and advanced presentation tools.",
      image: "/images/ab.png",
    },
    {
      title: "Spa & Massage",
      description:
        "Indulge in a luxurious spa experience with our range of massages and treatments designed to relax your body and mind. Indulge in a luxurious spa experience with our range of massages and treatments designed to relax your body and mind.",
      image: "/images/bg3.png",
    },
    {
      title: "Laundry Service",
      description:
        "Our efficient laundry services ensure your clothes are clean and fresh, allowing you to focus on what truly matters. Our efficient laundry services ensure your clothes are clean and fresh, allowing you to focus on what truly matters.",
      image: "/images/aa.png",
    },
  ];
  

  return (
    <section className="facility-wrapper">
        <div className="about-hero">
        <div className="hero-overlay">
          <h2 className="hero-heading">Facilities</h2>
          <p className="hero-subheading">
          Stay updated with the latest happenings at our hotels! From exciting events and special offers to exclusive insights and behind-the-scenes stories.
          </p>
          <br /><br />
          <div className="back-link">
             <Link to="/">
              <span>Home</span>
            </Link>
            <span> / Facilities</span>
         </div>
        </div>
      </div>
      <div className="facil">
      {facilitiesData.map((facility, index) => (
        <div
          className="facility-section"
          key={index}
          ref={(el) => (sectionsRef.current[index] = el)}
        >
          <div className="facility-image">
            <img src={facility.image} alt={facility.title} />
          </div>
          <div className="facility-content">
            <h2>{facility.title}</h2>
            <p>{facility.description}</p>
          </div>
        </div>
      ))}
      </div>
    </section>
  );
};

export default Facilities;
