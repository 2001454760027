import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Check if the user has previously accepted or declined cookies
    const consentGiven = localStorage.getItem('cookieConsent');

    if (!consentGiven) {
      // Delay the banner visibility by 10 seconds
      const visibilityTimer = setTimeout(() => {
        setShowBanner(true);

        // Set an additional timer to hide the banner after 30 seconds if no interaction
        const autoHideTimer = setTimeout(() => {
          setShowBanner(false);
        }, 30000); // 30 seconds (30000 milliseconds)

        // Cleanup autoHideTimer if user interacts
        return () => clearTimeout(autoHideTimer);
      }, 10000); // 10 seconds (10000 milliseconds)

      // Cleanup visibility timer if the component unmounts before 10 seconds
      return () => clearTimeout(visibilityTimer);
    }
  }, []);

  const handleAccept = () => {
    // Store the user's consent in localStorage
    localStorage.setItem('cookieConsent', 'true');
    setShowBanner(false);
  };

  const handleDecline = () => {
    // Store the user's decline choice in localStorage (if needed)
    localStorage.setItem('cookieConsent', 'declined');
    setShowBanner(false);
  };

  const handleClose = () => {
    // Close the banner without storing consent/decline choice
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <div style={styles.cookieBanner}>
      <p style={styles.p}>
        We are using cookies to improve your experience. By continuing, you agree to our use of cookies.
      </p>
      <div style={styles.buttonContainer}>
        <button onClick={handleAccept} style={styles.button}>Accept</button>
        <button onClick={handleDecline} style={styles.button}>Decline</button>
      </div>
      {/* Close button using FontAwesome */}
      <button onClick={handleClose} style={styles.closeButton}>
        <FaTimes />
      </button>
    </div>
  );
};

const styles = {
  cookieBanner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    bottom: '50px',
    width: '100%',
    backgroundColor: '#333',
    color: '#fff',
    padding: '15px 0',
    textAlign: 'center',
    zIndex: '5000',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  },
  p: {
    fontSize: '14px',
    width: '70%',
    textAlign: 'center',
    margin: '10px 0',
  },
  buttonContainer: {
    display: 'flex',
    width: '40%',
    margin: '5px auto',
    justifyContent: 'space-between',
  },
  button: {
    margin: '10px',
    padding: '8px 12px',
    backgroundColor: 'var(--primary-color)',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'transparent',
    border: 'none',
    color: '#fff',
    fontSize: '16px',
    cursor: 'pointer',
  },
};

export default CookieConsent;
