import React, { useEffect, useState } from "react";
import { FaInstagram } from "react-icons/fa";
import { collection, getDocs } from "firebase/firestore";
import { Link } from "react-router-dom";
import "./Instagram.css";
import { db } from "../firebase";

const Instagram = () => {
  const [posts, setPosts] = useState([]);

  // Fetch Instagram posts from Firestore
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "instagram"));
        const postsArray = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPosts(postsArray);
      } catch (e) {
        console.error("Error getting posts: ", e);
      }
    };

    fetchPosts();
  }, []);

  return (
    <div className="instagram-container">
      <h3>Our Instagram</h3>
      <h2>@zeavixeventandmore</h2>
      <div className="inst-con">
        {posts.map((post) => (
          <Link key={post.id} to={post.link}>
            <div className="inst-box">
              <img src={post.image} alt="Instagram Post" />
              <div className="overlay">
                <h1 className="hero-heading">
                  <FaInstagram />
                </h1>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Instagram;
