import React, { useEffect, useState } from 'react';
import {BrowserRouter, Routes, Route, Link} from 'react-router-dom';

import Navbar from './components/nav/Navbar';
import Services from './components/Services';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/nav/Footer';
import Home from './pages/Home';
import ScrollToTop from './components/ScrollToTop';
import { MdKeyboardArrowUp } from "react-icons/md";
import "./App.css"
import ExploreNow from './components/ExploreNow';
import {  FaWhatsappSquare } from 'react-icons/fa';
import CookieConsent from './components/CookieConsent';
import AddService from './components/admin/AddService';
import ServiceDisplay from './components/admin/ServiceDisplay';
import AdminDashboard from './components/admin/AdminDashboard';
import ServiceDetail from './components/admin/ServiceDetail';
import AOSProvider from './components/AOSProvider';
import Preloader from './components/Preloader';
import Facilities from './components/Facilities.';
import { AuthProvider } from './components/auth/AuthContext';
import ProtectedRoute from './components/auth/ProtectedRoute';
import Login from './components/auth/Login';
import Signup from './components/auth/Signup';


function App() {
  const [showScrollButton, setShowScrollButton] = useState(false); 

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll to top functionality
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <div className='app'>
    
      <BrowserRouter>
      <AuthProvider>
        <ScrollToTop/>
        <CookieConsent />

        { /* Scroll to Top Button */}
        {showScrollButton && (
         <button className="scroll-to-top" onClick={scrollToTop}>
           <MdKeyboardArrowUp />
         </button>
        )}  

        <a href={"https://wa.me/2348142471208"} className='watsap'>
          <FaWhatsappSquare/>
        </a>
      
      <AOSProvider/>
      <Navbar/>
      <Preloader>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about" element={<About />} />
          <Route path="/explorenow" element={<ExploreNow />} />
          <Route path="/add" element={<AddService />} />
          <Route path="/display" element={<ServiceDisplay />} />
          <Route path="/service/:name" element={<ServiceDetail />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route
            path="/admin"
            element={
              <ProtectedRoute roleRequired="admin">
                <AdminDashboard />
              </ProtectedRoute>
            }/>
            {/* <Route path="/admin" element={<AdminDashboard />} /> */}

          <Route path="/facilities" element={<Facilities />} />
        </Routes>
        </Preloader>
        <Footer/>
        </AuthProvider>
      </BrowserRouter>

    </div>
  );
}

export default App;
