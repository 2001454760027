import React from 'react';
import './Hero.css';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <div className="hero-container">
      <div className="hero-overlay">
        <div className="hero-content">
          <p className='hero-p'>ORGANISED BY ZEAVIX</p>
          <h1 className="hero-title" >Welcome to ZEAVIX</h1>
          <p className="hero-subtitle">
            Discover Short Stays, Events, and More!
          </p>
          <Link to="/explorenow" className="hero-button">
             Explore Now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Hero;
