import React, { useState, useRef, useEffect } from "react";
import "./Carousel.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const Carousel5 = () => {
  const slides = [
    {
      id: 1,
      image: "/images/ab.png",
      alt: "Innovative Solutions",
      title: "Creative Excellence",
      description: "Discover exceptional solutions tailored to your business growth.",
    },
    {
      id: 2,
      image: "/images/bed1.png",
      alt: "Collaborative Workspaces",
      title: "Empowered Collaboration",
      description: "Empower your team with tools that enhance productivity and creativity.",
    },
    {
      id: 3,
      image: "/images/bg5.png",
      alt: "Scalable Technology",
      title: "Technology that Grows",
      description: "Leverage scalable solutions for long-term success and adaptability.",
    },
    {
      id: 4,
      image: "/images/bg6.png",
      alt: "Customer-Centric Approach",
      title: "Customer First",
      description: "Our focus is delivering solutions that maximize client satisfaction.",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [dragStartX, setDragStartX] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState(0);

  const handleTouchStart = (e) => {
    setDragging(true);
    setDragStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (dragging) {
      const currentTouchX = e.touches[0].clientX;
      setDragOffset(currentTouchX - dragStartX);
    }
  };

  const handleTouchEnd = () => {
    if (dragOffset > 50 && currentIndex > 0) {
      // Swipe right
      setCurrentIndex((prev) => prev - 1);
    } else if (dragOffset < -50 && currentIndex < slides.length - 1) {
      // Swipe left
      setCurrentIndex((prev) => prev + 1);
    }
    setDragging(false);
    setDragOffset(0); // Reset drag offset
  };

  return (
    <div
      className="carousel-container"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div
        className="carousel-inner"
        style={{
          transform: `translateX(calc(-${currentIndex * 100}% + ${dragging ? dragOffset : 0}px))`,
          transition: dragging ? "none" : "transform 0.5s ease",
        }}
      >
        {slides.map((slide) => (
          <div key={slide.id} className="carousel-item">
            <img src={slide.image} alt={slide.alt} className="carousel-image" />
            <div className="carousel-caption">
              <h2>{slide.title}</h2>
              <p>{slide.description}</p>
            </div>
          </div>
        ))}
      </div>
      <button
        className="carousel-control-prev"
        onClick={() => setCurrentIndex((prev) => (prev > 0 ? prev - 1 : slides.length - 1))}
      >
        <IoIosArrowBack />
      </button>
      <button
        className="carousel-control-next"
        onClick={() => setCurrentIndex((prev) => (prev + 1) % slides.length)}
      >
        <IoIosArrowForward />
      </button>
    </div>
  );
};

export default Carousel5;
