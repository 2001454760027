import React from 'react'
import Hero from '../components/Hero'
import LuxuryAdvert from '../components/LuxuryAdvert.jsx'
import Carousel from '../components/Carousel.jsx'
import Carousel2 from '../components/Carousel2.jsx'
import Carousel3 from '../components/Carousel3.jsx'
import Carousel5 from '../components/Carousel5.jsx'

const Home = () => {
  return (
    <div>
      <Hero/>
      {/* <Carousel/>
      <Carousel2/> */}
      <Carousel3/>
      <Carousel5/>
      <LuxuryAdvert/>
    </div>
  )
}

export default Home
