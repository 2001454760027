import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './ServiceDetail.css';
import Instagram from '../Instagram';
import RoomAmenities from '../RoomAmenities';
import { FaBed, FaUsers } from 'react-icons/fa';
import { SlSizeFullscreen } from 'react-icons/sl';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

const ServiceDetail = () => {
  const location = useLocation();
  const service = location.state?.service;

  const [currentImageIndex, setCurrentImageIndex] = useState(0); // Tracks the current image index

  if (!service) {
    return <div>Service not found</div>;
  }

  const images = [service.primaryImage, ...(service.secondaryImages || [])]; // Combine primary and secondary images

  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length); // Circular navigation
  };

  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length); // Circular navigation
  };

  return (
    <div className="service-detail" data-aos="fade-right">
      <div className="about-hero">
        <div className="hero-overlay">
          <h1 className="hero-heading"  data-aos="fade in" data-aos-duration="500">{service.name}</h1>
          <div className="details-icon">{service.icon}</div>
          <br />
          <br />
          <div className="back-link" data-aos="flip-right" data-aos-delay="500">
            <Link to="/" className="btn3">
              <span>←Home</span>
            </Link>
            /
            <Link to="/contact" className="btn3">
              <span>Contact</span>
            </Link>
          </div>
        </div>
      </div>

      <div className="service-box">
        <div className="service-box-box">
          <img
            src={images[currentImageIndex]}
            alt={`Image ${currentImageIndex + 1}`}
            className="primary-image"
            data-aos="zoom-in" data-aos-duration="1500"
          />
          <div className="navigation-buttons">
            <button onClick={handlePrevClick} className="prev-btn"><IoIosArrowBack /></button>
            <button onClick={handleNextClick} className="next-btn"><IoIosArrowForward /></button>
          </div>
        </div>

        <div className="service-detail-details" data-aos="fade-up" data-aos-duration="1000">
          <h1 data-aos="fade-right" data-aos-duration="1500">{service.name}</h1>
          <p className="price">${service.price}</p>

          <div className="service-icons-box">
            <p>
              <span>
                <FaUsers />
              </span>
              {service.numberOfGuests}
            </p>
            <p>
              <span>
                <SlSizeFullscreen />
              </span>
              {service.roomSize}
            </p>
            <p>
              <span>
                <FaBed />
              </span>
              {service.kingBed}
            </p>
          </div>
          <p data-aos="fade-left" data-aos-duration="1500">{service.description}</p>
          <p>
            <strong>Location:</strong> {service.location}
          </p>
          <p>
            <strong>Details:</strong> <br />
            {service.details}
          </p>
          <p>
            <strong>Available Space:</strong> {service.availableSpace}
          </p>
          <p>
            <strong>Status:</strong> {service.availability}
          </p>

          {/* Additional Features */}
          {service.additionalFeatures && service.additionalFeatures.length > 0 && (
            <div className="aditional">
              <strong>Additional Features:</strong>
              {service.additionalFeatures.map((feature, idx) => (
                <p key={idx}>
                  <span>✔</span>
                  {feature}
                </p>
              ))}
            </div>
          )}

          {/* Terms and Conditions */}
          {service.terms && (
            <div>
              <strong>Terms and Conditions:</strong>
              <p>{service.terms}</p>
            </div>
          )}

          {/* Contact Information */}
          {service.contactInfo && (
            <div>
              <strong>Contact Information:</strong>
              <p>{service.contactInfo}</p>
            </div>
          )}

          {/* Ratings */}
          {service.ratings && (
            <div>
              <strong>Ratings:</strong>
              <p>{service.ratings}/5</p>
            </div>
          )}
        </div>
      </div>
      <RoomAmenities />
      <Instagram />
    </div>
  );
};

export default ServiceDetail;
