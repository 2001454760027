import React, { useEffect, useState } from 'react';
import './Preloader.css';
import { useLocation } from 'react-router-dom';

const Preloader = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => setLoading(false), 1000); // 3 seconds delays
    return () => clearTimeout(timer);
  }, [location.pathname]);

  if (loading) {
  return (
    <div className="preloader">
      <h1>Loading</h1>
      <div className="loader">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </div>
  );
};

return children
};

export default Preloader;
