import React, { useState, useEffect } from 'react';
import { collection, addDoc, updateDoc, doc } from 'firebase/firestore';
import './AddService.css';
import { db } from '../../firebase';

const AddService = ({ service = null, onSave, onCancel }) => {
  const [formData, setFormData] = useState({
    name: '',
    location: '',
    description: '',
    details: '',
    numberOfGuests:'',
    roomSize:'',
    kingBed:'',
    availableSpace: '',
    availability: 'Available',
    primaryImage: '',
    secondaryImages: ['', '', ''],
    price: '',
    additionalFeatures: [''],
  });

  useEffect(() => {
    if (service) {
      setFormData(service);
    }
  }, [service]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (index, value) => {
    const updatedImages = [...formData.secondaryImages];
    updatedImages[index] = value;
    setFormData({ ...formData, secondaryImages: updatedImages });
  };

  const handleFeatureChange = (index, value) => {
    const updatedFeatures = [...formData.additionalFeatures];
    updatedFeatures[index] = value;
    setFormData({ ...formData, additionalFeatures: updatedFeatures });
  };

  const addFeatureField = () => {
    setFormData({ ...formData, additionalFeatures: [...formData.additionalFeatures, ''] });
  };

  const removeFeatureField = (index) => {
    const updatedFeatures = formData.additionalFeatures.filter((_, i) => i !== index);
    setFormData({ ...formData, additionalFeatures: updatedFeatures });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (service) {
        // Update existing service
        const serviceDocRef = doc(db, 'services', service.id);
        await updateDoc(serviceDocRef, formData);
      } else {
        // Add a new service
        const servicesCollectionRef = collection(db, 'services');
        await addDoc(servicesCollectionRef, formData);
      }

      onSave(formData);
      setFormData({
        name: '',
        location: '',
        description: '',
        details: '',
        numberOfGuests:'',
        roomSize:'',
        kingBed:'',
        availableSpace: '',
        availability: 'Available',
        primaryImage: '',
        secondaryImages: ['', '', ''],
        price: '',
        additionalFeatures: [''],
      });
    } catch (error) {
      console.error('Error saving service:', error);
    }
  };

  return (
    <div className="add-service">
      <form onSubmit={handleSubmit}>
        <h2>{service ? 'Edit Service' : 'Add Service'}</h2>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          placeholder="Item Name"
          required
        />
        <input
          type="text"
          name="location"
          value={formData.location}
          onChange={handleInputChange}
          placeholder="Item Location"
          required
        />
        <input
          type="number"
          name="kingBed"
          value={formData.kingBed}
          onChange={handleInputChange}
          placeholder="King Bed"
          required
        />
        <input
          type="text"
          name="roomSize"
          value={formData.roomSize}
          onChange={handleInputChange}
          placeholder="Room Size"
          required
        />
        <label htmlFor="numberOfGuests">Number of Guests:</label>
     <input
      type="number"
      name="numberOfGuests"
      value={formData.numberOfGuests}
      onChange={handleInputChange}
      placeholder="Enter number of guests"
      required
     />

        <textarea
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          placeholder="Description"
          required
        />
        <textarea
          name="details"
          value={formData.details}
          onChange={handleInputChange}
          placeholder="Details"
          required
        />
        <input
          type="text"
          name="availableSpace"
          value={formData.availableSpace}
          onChange={handleInputChange}
          placeholder="Available Space"
          required
        />
        <select
          name="availability"
          value={formData.availability}
          onChange={handleInputChange}
        >
          <option value="Available">Available</option>
          <option value="Booked">Booked</option>
        </select>
        <input
          type="url"
          name="primaryImage"
          value={formData.primaryImage}
          onChange={handleInputChange}
          placeholder="Primary Image URL"
          required
        />
        {formData.secondaryImages.map((img, index) => (
          <input
            key={index}
            type="url"
            value={img}
            onChange={(e) => handleImageChange(index, e.target.value)}
            placeholder={`Secondary Image URL ${index + 1}`}
          />
        ))}
        <input
          type="text"
          name="price"
          value={formData.price}
          onChange={handleInputChange}
          placeholder="Price"
          required
        />
        
        <div className="additional-features">
          <h3>Additional Features</h3>
          {formData.additionalFeatures.map((feature, index) => (
            <div key={index} className="feature-field">
              <input
                type="text"
                value={feature}
                onChange={(e) => handleFeatureChange(index, e.target.value)}
                placeholder={`Feature ${index + 1}`}
              />
              <button
                type="button"
                onClick={() => removeFeatureField(index)}
                disabled={formData.additionalFeatures.length === 1}
              >
                Remove
              </button>
            </div>
          ))}
          <button type="button" onClick={addFeatureField}>
            Add Feature
          </button>
        </div>
        <div className="form-actions">
          <button type="submit">{service ? 'Save Changes' : 'Add Service'}</button>
          <button type="button" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddService;
