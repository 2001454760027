import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { collection, getDocs, query, limit, startAfter } from "firebase/firestore";
import "./ServiceDisplay.css";
import { db } from "../../firebase";

const ServiceDisplay = () => {
  const [services, setServices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [lastVisible, setLastVisible] = useState(null); // Track the last document for pagination
  const [loading, setLoading] = useState(false); // For loading state
  const [totalPages, setTotalPages] = useState(0); // Total pages count
  const servicesPerPage = 5; // Number of items to display per page
  const navigate = useNavigate();

  useEffect(() => {
    const fetchServices = async () => {
      setLoading(true);
      try {
        const servicesRef = collection(db, "services");
        const firstPageQuery = query(servicesRef, limit(servicesPerPage));
        const querySnapshot = await getDocs(firstPageQuery);
        
        const servicesList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        
        setServices(servicesList);
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);

        // Calculate total number of pages
        const totalServicesSnapshot = await getDocs(servicesRef);
        setTotalPages(Math.ceil(totalServicesSnapshot.size / servicesPerPage));
      } catch (error) {
        console.error("Error fetching services: ", error);
      }
      setLoading(false);
    };

    fetchServices();
  }, []);

  // Fetch next page of services
  const loadNextPage = async () => {
    setLoading(true);
    try {
      const servicesRef = collection(db, "services");
      const nextPageQuery = query(servicesRef, startAfter(lastVisible), limit(servicesPerPage));
      const querySnapshot = await getDocs(nextPageQuery);

      const servicesList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setServices((prevServices) => [...prevServices, ...servicesList]);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    } catch (error) {
      console.error("Error fetching next page of services: ", error);
    }
    setLoading(false);
  };

  // Handle page button click
  const handlePageClick = async (pageNumber) => {
    setLoading(true);
    try {
      const servicesRef = collection(db, "services");
      const pageQuery = query(servicesRef, limit(servicesPerPage), startAfter(lastVisible));
      const querySnapshot = await getDocs(pageQuery);

      const servicesList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setServices(servicesList);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setCurrentPage(pageNumber);
    } catch (error) {
      console.error("Error fetching services: ", error);
    }
    setLoading(false);
  };

  const handleItemClick = (service) => {
    navigate(`/service/${service.name}`, { state: { service } });
  };

  return (
    <div className="service-display">
      <div className="about-hero">
        <div className="hero-overlay">
          <h1 className="hero-heading" data-aos="fade-up">Our Meticulous Services</h1>
         <p className="hero-subheading" data-aos="zoom-in" data-aos-duration="1500">Indulge in the ultimate blend of elegance and comfort in our meticulously designed rooms. Choose your room today.</p>
          <br />
          <br />
          <div className="back-link">
            <Link to="/" className="btn3">
              <span>←Home</span>
            </Link>
            /
            <Link to="/services" className="btn3">
              <span>Services</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="service-list">
        {services.map((service) => (
          <div
            key={service.id}
            className="service-item"
            onClick={() => handleItemClick(service)}
            data-aos="zoom-in" data-aos-duration="1500"
          >
            <img
              src={service.primaryImage}
              alt={`${service.name}`}
              className="primary-image"
            />
            <div className="service-item-div">
              <h2>{service.name}</h2>
              <p>{service.details}</p>
              <p className="price">${service.price}</p>
              <p>
                <strong>Status:</strong> {service.availability}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="pagination-controls">
        {currentPage > 1 && (
          <button onClick={() => handlePageClick(currentPage - 1)}>Previous</button>
        )}
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            onClick={() => handlePageClick(index + 1)}
            className={currentPage === index + 1 ? "active" : ""}
            data-aos="flip-left" data-aos-delay="500">
            {index + 1}
          </button>
        ))}
        {currentPage < totalPages && (
          <button onClick={loadNextPage} disabled={loading} data-aos="flip-right" data-aos-delay="500">
            {loading ? "Loading..." : "Next"}
          </button>
        )}
      </div>
    </div>
  );
};

export default ServiceDisplay;
